import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APIClient } from "../../utils/axiosCustomize";

const axios = new APIClient();

export const fetchSortConfig = createAsyncThunk(
  'document/fetchSortConfig',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/OrderSort');
      if (response.success) {
        return {
          id: response.data.id,
          columnSort: response.data.columnSort,
          typeSort: response.data.typeSort
        };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Could not fetch config');
    }
  }
);

export const saveSortConfig = createAsyncThunk(
  'document/saveSortConfig',
  async (config, { rejectWithValue }) => {
    try {
      const response = await axios.put('/OrderSort/update', config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Could not save config');
    }
  }
);

const initialState = {
  defaultActiveTabKey: '2',
  sortConfig: {
    id: 1,
    columnSort: 'CreateDate',
    typeSort: 1
  },
  status: 'idle',
  error: null
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setDefaultActiveKey: (state, action) => {
      state.defaultActiveTabKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchSortConfig.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchSortConfig.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.sortConfig = action.payload;
      state.error = null;
    })
    .addCase(fetchSortConfig.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    })

    .addCase(saveSortConfig.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(saveSortConfig.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.sortConfig = action.payload;
      state.error = null;
    })
    .addCase(saveSortConfig.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  }
});

export const { setDefaultActiveKey } = documentSlice.actions;

export const selectSortConfig = (state) => state.document.sortConfig;
export const selectSortStatus = (state) => state.document.status;
export const selectSortError = (state) => state.document.error;

export default documentSlice.reducer;