import { Col, Row } from "antd";

const UserQuantity = ({ totalUser }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col className="!pl-0 flex items-center">
        <div className="text-[0.9rem] my-1 truncate flex items-center">
          <div className="text-primary text-[0.9rem]">Tổng nhân viên:</div>
          <div className="border-[1px] border-solid border-primary p-[2px] px-2 rounded-md ml-2 text-[0.8rem] text-primary bg-[#ff990023]">
            {totalUser || 0} nhân viên
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default UserQuantity;
