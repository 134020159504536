import { APIClient } from "../utils/axiosCustomize"
import { endpoints } from "../utils/constants/endpoints"

const axios = new APIClient()

export const getTagsList = async (query) => {
  try {
    const { data, totalCount, pageSize, pageIndex } = await axios.get(endpoints.Tag + "/gettags", {
      ...query,
    })

    const dataWithKey = data.map(i => {
      return { ...i, key: i.Id }
    })

    return { data: dataWithKey, totalCount, pageSize, pageIndex }
  } catch (err) {
    return []
  }
}

export const insertTag = async (payload) => {
  const res = await axios.post(endpoints.Tag + "/insert", payload);
  return res;
};

export const updateTag = async ({ id, tagName }) => {
  const data = await axios.put(endpoints.Tag + `/update`, { id, tagName })
  return data
}

export const deleteTag = async (id) => {
  const data = await axios.delete(endpoints.Tag + `/delete?id=${id}`)
  return data
}