import React, { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  RollbackOutlined,
  LockOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Button,
  Avatar,
  Dropdown,
  Modal,
  Form,
  Input,
  message, Flex, Space,
} from "antd";
import { useNavigate } from "react-router-dom/dist";
import { routes } from "../../utils/constants/routes";
import { useDispatch } from "react-redux";
import { changePass } from "../../app/features/users/thunks";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";
import { logout } from "../../app/features/users/usersSlice";

const { Header } = Layout;

const HeaderMain = ({ collapsed, setCollapsed }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const userDetails = useAppSelector(
    `${ReducersName.Users}.userLoginInfo.user`
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const changePassword = (data) => {
    data.userName = userDetails.userName;
    dispatch(changePass(data)).then((action) => {
      if (!action.payload?.errorCode) {
        setVisible(false);
        form.resetFields();
        message.success("Đổi mật khẩu thành công!");
      } else {
        message.error("Mật khẩu cũ không chính xác!");
      }
    });
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const items = [
    {
      label: "Thông tin cá nhân",
      key: "1",
      icon: <UserOutlined />,
      onClick: () => navigate(routes.profile),
    },
    {
      label: "Đổi mật khẩu",
      key: "2",
      icon: <LockOutlined />,
      onClick: () => setVisible(true),
    },
    {
      label: "Đăng xuất",
      key: "3",
      icon: <RollbackOutlined />,
      onClick: handleLogout,
    },
  ];

  const getTextRole = () => {
    let text;

    if (userDetails?.isSuperAdmin) {
      text = "Quản trị cấp cao";
    } else if (userDetails?.isSuperUser) {
      text = "Quản trị";
    } else if (userDetails?.isLeader) {
      text = "Quản lý"
    } else {
      text = "Người dùng";
    }

    return text;
  }

  return (
    <>
      <Header className="bg-white pl-0">
        <div className="h-full flex justify-between items-center">
          <div className="flex items-center">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </div>
          <div className="h-[70%] flex cursor-pointer select-none">
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              onOpenChange={setIsOpen}
            >
              <Flex className="justify-between gap-1 items-center text-[#333]">
                <Avatar
                  src={userDetails?.avataUrl || null}
                  size={40}
                  icon={<UserOutlined />}
                  style={{
                    background: "#FF9900",
                  }}
                />
                <Flex className="flex-col h-full">
                  <div className="flex justify-center items-center text-[16px] font-semibold h-1/2 uppercase">
                    {userDetails?.fullName}
                    <Space className="ml-1">
                      <CaretUpOutlined className={`text-[14px] transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
                    </Space>
                  </div>
                  <div className="flex justify-center items-center text-[12px] font-light h-1/2">
                    {getTextRole()}
                  </div>
                </Flex>
              </Flex>
            </Dropdown>
          </div>
        </div>
      </Header>
      <div>
        <Modal
          title="Đổi mật khẩu"
          open={visible}
          footer={false}
          onCancel={handleCancel}
        >
          <Form layout="vertical" onFinish={changePassword}>
            <Form.Item
              label="Mật khẩu cũ"
              name="oldPassword"
              rules={[
                { required: true, message: "Vui lòng nhập mật khẩu cũ!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Mật khẩu mới"
              name="newPassword"
              dependencies={["oldPassword"]}
              rules={[
                { required: true, message: "Vui lòng nhập mật khẩu mới!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("oldPassword") !== value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Mật khẩu mới không được trùng với mật khẩu cũ!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Nhập lại mật khẩu"
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                { required: true, message: "Vui lòng nhập lại mật khẩu!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Mật khẩu nhập lại không khớp!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item className="text-right">
              <Button
                style={{
                  backgroundColor: "#ffa827",
                  height: "40px",
                  color: "#fff",
                }}
                className="custom-button"
                size="large"
                htmlType="submit"
              >
                Lưu
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default HeaderMain;
