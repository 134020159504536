import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ReducersName } from "../../utils/constants";
import { localStorageKeys } from "../../utils/constants/local_storage";
import { useAppSelector } from "../../hooks/app";
import { setUserToken } from "../../app/features/users/usersSlice";
import BasicLayout from "../../Layouts/BasicLayout";
import AuthenticationLayout from "../../Layouts/Authentication";
import Login from "../../pages/Auth/Login";
import Profile from "../../pages/Profile";
import User from "../../pages/User";
import Error from "../../pages/Error/index";
import CreateDocument from "../../pages/Documents/CreateDocument";
import { getDocumentDetail } from "../../services/document";
import { getDepartments } from "../../services/department";
import ServerError from ".././error/server-error";
import AuthError from ".././error/auth-error";
import NotFound from "../../pages/Error/notfound";
import { routes } from "../../utils/constants/routes";
import Categories from "../../pages/Categories";

const ListDepartment = React.lazy(() =>
  import("../../pages/Department/Listdepartment")
);
const ListDocument = React.lazy(() =>
  import("../../pages/Documents/ListDocument")
);
const ViewDocument = React.lazy(() =>
  import("../../pages/Documents/ViewDocument")
);
const ListLogs = React.lazy(() => import("../../pages/Logs/ListLog"));
const ListTags = React.lazy(() => import("../../pages/Tags/ListTags"));

const Providers = () => {
  const dispatch = useDispatch();
  const userToken = useAppSelector(`${ReducersName.Users}.userLoginInfo.token`);
  const [firstCheck, setFirstCheck] = useState(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={"/documents"} replace />,
        },
        {
          path: "*",
          element: <Navigate to={"/documents"} replace />,
        },
        // {
        //   path: "/home",
        //   element: <Home />,
        // },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: routes.categories,
          element: <Categories />,
        },
        {
          path: "/employees",
          element: <User />,
        },
        {
          path: "departments",
          children: [
            {
              index: true,
              element: <ListDepartment />,
              loader: async () => {
                const data = await getDepartments();
                return data;
              },
            },
          ],
        },
        {
          path: "documents",
          children: [
            {
              index: true,
              element: <ListDocument />,
            },
            {
              path: "create",
              element: <CreateDocument />,
            },
            {
              path: "view/:id/:slug",
              loader: async ({ params: { id, slug } }) => {
                const data = await getDocumentDetail(id);
                return data;
              },
              element: <ViewDocument />,
            },
          ],
        },
        {
          path: "/logs",
          element: <ListLogs />,
        },
        {
          path: "/tags",
          element: <ListTags />,
        },
      ],
    },
    {
      path: "error",
      element: <Error />,
    },
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: "/server-error",
      element: <ServerError />,
    },
    {
      path: "/auth-error",
      element: <AuthError />,
    },
  ]);

  const authRouter = createBrowserRouter([
    {
      path: "/",
      element: <AuthenticationLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={"/login"} replace />,
        },
        {
          path: "*",
          element: <Navigate to={"/login"} replace />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/server-error",
          element: <ServerError />,
        },
      ],
    },
  ]);

  useEffect(() => {
    const token = localStorage.getItem(localStorageKeys.TOKEN);
    const user = JSON.parse(localStorage.getItem(localStorageKeys.MY_INFO));

    if (!!token) {
      dispatch(
        setUserToken({
          token,
          user,
        })
      );
    }
    setFirstCheck(true);
  }, [dispatch]);

  if (!firstCheck) return null;

  return (
    <RouterProvider
      router={userToken ? router : authRouter}
      fallbackElement={<Spin size="large" />}
    />
  );
};

export default Providers;
