import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIClient } from "../../utils/axiosCustomize";

const axios = new APIClient();

export const fetchCategories = createAsyncThunk(
  'category/fetchCategories',
  async (params, { rejectWithValue }) => {
    try {
      return await axios.get('/Category/getCategorys', { params });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCategoryById = createAsyncThunk(
  'category/getById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get('/Category/getById', { params: { id } });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createCategory = createAsyncThunk(
  'category/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/Category/insert', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  'category/update',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put('/Category/update', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'category/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete('/Category/delete', { params: { id } });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  categories: [],
  selectedCategory: null,
  loading: false,
  error: null,
  totalCount: 0,
  currentPage: 1,
  pageSize: 100
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    clearSelectedCategory: (state) => {
      state.selectedCategory = null;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchCategories.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload.data;
      state.totalCount = action.payload.totalCount;
      state.pageSize = action.payload.pageSize;
      state.currentPage = action.payload.pageIndex;
    })
    .addCase(fetchCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(getCategoryById.fulfilled, (state, action) => {
      state.selectedCategory = action.payload.data;
    })

    .addCase(createCategory.pending, (state) => {
      state.loading = true;
    })
    .addCase(createCategory.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(createCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(updateCategory.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateCategory.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(updateCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(deleteCategory.pending, (state) => {
      state.loading = true;
    })
    .addCase(deleteCategory.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(deleteCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { setCurrentPage, setSelectedCategory, clearSelectedCategory } = categorySlice.actions;
export default categorySlice.reducer;