import React from "react";
import {
  UserOutlined,
  FolderOpenOutlined,
  FileSearchOutlined,
  TagsOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.jpg";
import { routes } from "../../utils/constants/routes";
import { Menu, MenuItem } from "react-pro-sidebar";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";

const { Sider } = Layout;
export const parentRoute = {
  [routes.home]: routes.home,
};

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const route = `/${location.pathname
    .split("/")
    .filter((a) => !!a)
    .join("/")}`;

  const userDetails = useAppSelector(`${ReducersName.Users}.userLoginInfo.user`);
  const isSupperUser = userDetails?.isSuperUser;
  const isLeader = userDetails?.isLeader;

  const menuItems = [
    {
      key: "documents",
      name: "Tài liệu",
      route: "/documents",
      icon: <FolderOpenOutlined />,
      isAllow: true
    },
    {
      key: "categories",
      name: "Quản lý danh mục",
      route: routes.categories,
      icon: <UnorderedListOutlined />,
      isAllow: isSupperUser
    },
    {
      key: "departments",
      name: "Quản lý bộ phận",
      route: "/departments",
      icon: <FolderOpenOutlined />,
      isAllow: isSupperUser
    },
    {
      key: "users",
      name: "Quản lý nhân viên",
      route: "/employees",
      icon: <UserOutlined />,
      isAllow: isSupperUser || isLeader
    },
    {
      key: "tags",
      name: "Quản lý tags",
      route: "/tags",
      icon: <TagsOutlined />,
      isAllow: isSupperUser || isLeader
    },
    {
      key: "logs",
      name: "Quản lý logs",
      route: "/logs",
      icon: <FileSearchOutlined />,
      isAllow: isSupperUser
    },
  ];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={230}
      style={{
        position: "fixed",
        left: 0,
        height: "100vh",
      }}
      theme="light"
    >
      <div className="logo-vertical">
        <a href={"/"}>
          <img src={logo} alt="" />
        </a>
      </div>
      <Menu
        style={{
          borderInline: "none",
          backgroundColor: "transparent",
          padding: "0 5px",
        }}
      >
        {menuItems.map((item) => {
          if (item.isAllow) {
            return (
              <MenuItem
                key={item.key}
                className="custom-menu font-medium text-[15px]"
                active={`/${route.split("/")[1]}` === item.route}
                icon={item.icon}
                onClick={() => navigate(item.route)}
              >
                {item.name}
              </MenuItem>
            )
          }
        })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
