import React from "react";
import { useNavigate } from "react-router";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Flex } from "antd";

function HeaderContent({ children }) {
  const nav = useNavigate();

  const backToPage = () => {
    nav(-1);
  };

  return (
    <Flex>
      <div
        className="flex items-center cursor-pointer mr-[10px] hover:opacity-80"
        onClick={() => backToPage()}
      >
        <IoMdArrowRoundBack />
      </div>
      {children}
    </Flex>
  );
}

export default HeaderContent;
