import React, {useEffect, useState} from 'react'
import {Flex, theme, Select} from 'antd'
import { getAllTags } from '../../../services/document'

function Tags ({ onChange, exitsTags }) {
  const [tags, setTags] = useState(exitsTags ?? [])
  const [tagsData, setTagsData] = useState([])
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getTagsData();
  }, []);

  const getTagsData = async () => {
    const res = await getAllTags( { page });
    if (res) {
      setPage((prev) => prev + 1);
      setTotalPages(res.totalCount);
      setTagsData([...tagsData, ...res.data]);
    }
  }

  const handleLoadMore = async (e) => {
    const { target } = e;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      page <= totalPages &&
      totalPages > tagsData.length
    ) {
      await getTagsData();
    }
  };

  const handleChangeSelect = (event) => {
    setTags(event);
    onChange(event);
  }

  return (
    <Flex gap="4px 0" wrap="wrap">
      {
        <Select
          mode="tags"
          allowClear
          style={{
            width: '60%',
          }}
          placeholder="Chọn tag"
          defaultValue={(tags || []).map((tag) => {
            return {
              label: tag.tagName,
              value: tag.id,
            }
          })}
          onChange={handleChangeSelect}
          options={(tagsData || []).map((tag) => {
            return {
              label: tag.tagName,
              value: tag.id,
            }
          })}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onPopupScroll={handleLoadMore}
        />
      }
    </Flex>
  )
}

export default Tags