import { APIClient } from "../utils/axiosCustomize";
import { endpoints } from "../utils/constants/endpoints";

const axios = new APIClient();

export const addAttachmentToDocument = async ({ contentId, fileId }) => {
  const res = await axios.post(`/AttachFile/insert`, { contentId, fileId });
  return res;
};

export const deleteAttachmentFromDoc = async (attachmentId) => {
  const res = await axios.delete(`/AttachFile/delete?id=${attachmentId}`);
  return res;
};

export const getListPublicDocument = async (query) => {
  const { data, totalCount, pageSize, pageIndex } = await axios.get(
    endpoints.Document + "/getContentsPublic",
    {
      ...query,
    }
  );

  const dataWithKey = data
    ? data.map((i) => {
        return { ...i, key: i.Id };
      })
    : [];

  return { data: dataWithKey, totalCount, pageSize, pageIndex };
};

export const getListDocument = async (query) => {
  const { data, totalCount, pageSize, pageIndex } = await axios.get(
    endpoints.Document + "/getContentsPrivate",
    {
      ...query,
    }
  );

  const dataWithKey = data
    ? data.map((i) => {
        return { ...i, key: i.Id };
      })
    : [];

  return { data: dataWithKey, totalCount, pageSize, pageIndex };
};

export const getDocumentDetail = async (id) => {
  const { data } = await axios.get(endpoints.Document + `/getById`, { id });
  return data;
};

export const insertDocument = async (payload) => {
  const res = await axios.post(endpoints.Document + "/insert", payload);
  return res;
};

export const deleteDocument = async (id) => {
  const data = await axios.delete(endpoints.Document + `/delete?id=${id}`);
  return data;
};

export const updateDocument = async (data) => {
  const response = await axios.put(endpoints.Document + "/update", data);
  return response?.data;
};

export const getDocumentDateRange = async ({ startDate, endDate }) => {
  const { success, message, data, errorCode } = await axios.get(
    endpoints.Document + "/getSumaryContentUpload",
    {
      startDate,
      endDate,
    }
  );

  return { data, success, message, errorCode };
};

export const getAllTags = async (query) => {
  const { data, totalCount, pageSize, pageIndex } = await axios.get(
    endpoints.Tag + "/getTags",
    {
      ...query,
      sortBy: "TagName",
    }
  );

  const dataHasValue = data
    ? data.map((i) => ({ ...i, value: i.tagName }))
    : null;

  return { data: dataHasValue, totalCount, pageSize, pageIndex };
};
