import loginBG from "../../assets/images/loginBG.svg";
import logo from "../../assets/images/logo.jpg";
import { Button, Form, Input, message } from "antd";
import { login } from "../../app/features/users/thunks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { routes } from "../../utils/constants/routes";

export default function Login() {
  const dispatch = useDispatch();
  const [formLogin] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = (fieldValue) => {
    dispatch(login(fieldValue))
      .then((action) => {
        if (action.payload?.data) {
          message.success("Đăng nhập thành công!");
          navigate(routes.home);
        } else {
          for (let error in action.payload.errors) {
            action.payload.errors[error].forEach((err) => {
              message.error(err);
            });
          }
        }
      })
      .catch((error) => {
        console.error("Failed to login:", error);
      });
  };

  return (
    <>
      <div className="main">
        <div className="banner">
          <img src={loginBG} alt="" className="banner-login" />
          <img src={logo} alt="" className="logo" />
        </div>
        <div className="right">
          <div className="sign-in-form">
            <h2 className="title">
              Chào mừng đến với <span style={{ color: "#fd9900" }}>OKVIP</span>!
              👋
            </h2>
            <Form
              form={formLogin}
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 22,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Tên đăng nhập"
                name="userName"
                style={{
                  fontWeight: "bold",
                }}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên đăng nhập!",
                  },
                ]}
              >
                <Input size="large" placeholder="Nhập tên đăng nhập" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                style={{
                  fontWeight: "bold",
                }}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mật khẩu!",
                  },
                ]}
              >
                <Input.Password size="large" placeholder="Nhập mật khẩu" />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#ffa827",
                    width: "100%",
                    height: "40px",
                    color: "#fff",
                    marginTop: "30px",
                  }}
                  className="custom-button"
                  size="large"
                  htmlType="submit"
                >
                  Đăng nhập
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
