import { SearchOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import debounce from "debounce";
import { useMemo, useState } from "react";
import { ReducersName } from "../../utils/constants";
import { useAppSelector } from "../../hooks/app";

const UserSearch = ({ actionUsers, department }) => {
  const [departmentId, setDepartmentId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const userDetails = useAppSelector(
    `${ReducersName.Users}.userLoginInfo.user`
  );

  const onSearch = (fieldValues) => {
    if (!!fieldValues.fullName)
      fieldValues.fullName = fieldValues.fullName.trim();
    const filteredData = Object.fromEntries(
      Object.entries(fieldValues).filter(
        ([key, value]) => value !== undefined || value != null
      )
    );
    actionUsers(filteredData, "search");
  };

  // const getDataDepartments = async () => {
  //   const response = await getDepartments({ pageIndex: 1 });
  //   if (response) {
  //     setPage((prev) => prev + 1);
  //     setTotalPages(response.totalCount);
  //     setDataDepartments([...dataDepartments, ...response.data]);
  //   }
  // };

  // const handleLoadMore = async (e) => {
  //   const { target } = e;
  //   if (
  //     target.scrollTop + target.offsetHeight === target.scrollHeight &&
  //     page <= totalPages &&
  //     totalPages > dataDepartments.length
  //   ) {
  //     await getDataDepartments();
  //   }
  // };

  // useEffect(() => {
  //   getDataDepartments();
  // }, []);

  const handleOnchange = (event) => {
    const value = event.target.value.trim().replace(/\s\s+/g, " ");
    const data = {
      searchColumn: "username",
      searchText: value,
      departmentId: departmentId ? [departmentId] : undefined,
    };
    debouncedSearch(data);
  };

  const handleChangeSelect = (event) => {
    setDepartmentId(event);
    const data = {
      departmentId: [event],
    };
    if (!searchText) {
      debouncedSearch(data);
    }
  };

  const debouncedSearch = useMemo(() => {
    return debounce((data) => onSearch(data), 300);
  }, []);

  return (
    <div className="flex">
      <Input
        size="large"
        allowClear
        placeholder="Nhập Tên đăng nhập để tìm kiếm..."
        onChange={(event) => handleOnchange(event)}
        className="user-search"
        addonBefore={<SearchOutlined />}
      />
      {(userDetails.isLeader && userDetails.isSuperUser) ||
      userDetails.isSuperUser ? (
        <Select
          allowClear
          onChange={handleChangeSelect}
          size="large"
          className="user-search-select ml-4 !w-[200px]"
          placeholder="Chọn Bộ phận"
          options={(department || []).map((department) => {
            return {
              label: department.DepartmentName,
              value: department.Id,
            };
          })}
          // onPopupScroll={handleLoadMore}
        ></Select>
      ) : null}
    </div>
  );
};

export default UserSearch;
