import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginService,
  getMeService,
  getUserInforServices,
  updateProfile,
  getAllUsersService,
  deleteUserService,
  logoutService,
  changePassSerices,
  createUserService,
  updateUserService,
} from "../../../services/user";

export const login = createAsyncThunk("/login", async (value) => {
  try {
    const res = await loginService(value);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const logout = createAsyncThunk("logout", async () => {
  try {
    const res = await logoutService();
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const getMe = createAsyncThunk("/getMe", async () => {
  try {
    const res = await getMeService();
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const getUserInfor = createAsyncThunk("/getUserInfor", async (id) => {
  try {
    const res = getUserInforServices(id);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const update = createAsyncThunk("/update", async (data) => {
  try {
    const res = updateProfile(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const getAllUsers = createAsyncThunk("/getUsers", async (params) => {
  try {
    const res = await getAllUsersService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const deleteUser = createAsyncThunk("/delete", async (id) => {
  try {
    const res = await deleteUserService(id);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const changePass = createAsyncThunk("/changePass", async (data) => {
  try {
    const res = await changePassSerices(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const createUser = createAsyncThunk(
  "/create",
  async (data, thunkAPI) => {
    try {
      const res = await createUserService(data);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateUser = createAsyncThunk("/update", async (data) => {
  try {
    const res = await updateUserService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});
