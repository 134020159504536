import axios from "axios";
import { localStorageKeys } from "./constants/local_storage";
import { refreshTokenService } from "../services/user";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

const urlRefreshToken = "/api/auth/refresh";

const token = localStorage.getItem(localStorageKeys.TOKEN)
  ? localStorage.getItem(localStorageKeys.TOKEN)
  : null;

if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
// const apiClient = axios.create();

// axios.interceptors.response.use(
//   (response) => {
//     return response.data ? response.data : response;
//   },
//   (error) => {
//     console.log(error.response.data.error);
//     console.warn("Error status", error.response);
//     if (error.response) {
//       return refreshTokenAndRetryRequest(error.config);
//     } else
//       return Promise.reject(error); {
//     }
//   }
// );

export const setToken = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  localStorage.setItem(localStorageKeys.TOKEN, token);
};

axios.interceptors.response.use(
  (response) => {
    return response.data ? response.data : response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.warn("Error status", error.response);
    if (error.response && error.response.status === 500) {
      window.location.href = "/server-error";
    }
    if (error.response && error.response.status === 404) {
      window.location.href = "/not-found";
    }
    if (error.response && error.response.status === 403) {
      window.location.href = "/auth-error";
    }
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    if (
      error.response &&
      error.response.status === 419 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const newToken = await refreshTokenService();
      setToken(newToken);
      return window.location.reload();
    }

    return error.response.data;
  }
);

// const refreshTokenAndRetryRequest = async () => {
//   try {
//     let data = localStorage.getItem("data");

//     data = JSON.parse(data);
//     if (data) {
//       const param = {
//         refreshToken: data.token.refreshToken,
//         userId: data.user._id,
//       };

//       const response = await apiClient.post(urlRefreshToken, param);
//       if (response.data) {
//         const newAccessToken = response.data.data.accessToken;
//         localStorage.setItem(localStorageKeys.TOKEN, newAccessToken);
//       }
//     }
//   } catch (refreshError) {
//     console.error("Token refresh failed:", refreshError);
//     message.error("Phiên làm việc đã hết, vui lòng đăng nhập lại");
//     localStorage.clear();
//   }
// };
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  get = async (url, params) => {
    let response;

    let paramKeys = [];
    const token = localStorage.getItem(localStorageKeys.TOKEN)
      ? localStorage.getItem(localStorageKeys.TOKEN)
      : null;
    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });
      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      await axios
        .get(`${url}?${queryString}`, params)
        .then(function (res) {
          response = res;
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      await axios
        .get(`${url}`)
        .then(function (res) {
          response = res;
        })
        .catch(function (error) {
          if (error === "Request failed with status code 401") {
            axios
              .post(`${urlRefreshToken}`, null)
              .then((res) => {
                response = res;
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    }
    return response;
  };
  /**
   * post given data to url
   */
  post = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Put data
   */
  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };

  createWithFormData = (url, data) => {
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return axios.post(url, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
  };

  updateWithFormData = (url, data) => {
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return axios.put(url, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem(localStorageKeys.TOKEN);
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
