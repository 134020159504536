import React, { useState } from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";

const { Content } = Layout;

const Index = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sidebar collapsed={collapsed} />
      <Layout
        style={{
          ...(!collapsed
            ? {
                marginLeft: "230px",
                transition: "margin 0.2s",
              }
            : {
                marginLeft: "80px",
                transition: "margin 0.2s",
              }),
        }}
      >
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />

        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: "#fff",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Index;
