import { APIClient } from "../utils/axiosCustomize"
import { endpoints } from "../utils/constants/endpoints"

const axios = new APIClient()

export const getDepartments = async (query) => {
    try {
        const { data, totalCount, pageSize, pageIndex } = await axios.get(endpoints.Department + "/getDepartments", {
            pageSize: 100,
            ...query,
            // sortBy: "CreateDate",
            // sortType: "desc",
        })

        const dataWithKey = data.map(i => {
            return { ...i, key: i.Id }
        })

        return { data: dataWithKey, totalCount, pageSize, pageIndex }
    } catch (err) {
        return []
    }
}

export const getDepartmentDetail = async (id) => {
    const { data } = await axios.get(endpoints.Department + `/getById?id=${id}`)
    return data
}

export const insertDepartment = async ({ departmentName }) => {
    const data = await axios.post(endpoints.Department + `/insert`, { departmentName, isActive: true })
    return data
}

export const updateDepartment = async ({ id, departmentName }) => {
    const data = await axios.put(endpoints.Department + `/update`, { id, departmentName, isActive: true })
    return data
}

export const deleteDepartment = async (id) => {
    const data = await axios.delete(endpoints.Department + `/delete?id=${id}`)
    return data
}