import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Row, Spin } from "antd";
import { Suspense } from "react";
import Providers from "./components/providers";

function App() {
  return (
    <Suspense
      fallback={
        <Row
          style={{ height: "100vh", width: "100vw" }}
          justify="center"
          align="middle"
        >
          <Spin spinning />
        </Row>
      }
    >
      <Providers />
    </Suspense>
  );
}

export default App;
