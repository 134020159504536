const VIETNAMESE_MAP = {
    a: 'á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ',
    e: 'é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ',
    i: 'í|ì|ỉ|ĩ|ị',
    o: 'ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ',
    u: 'ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự',
    y: 'ý|ỳ|ỷ|ỹ|ỵ',
    d: 'đ'
};

function slug(input) {
    if (!input) return '';

    let output = input.toLowerCase();

    Object.entries(VIETNAMESE_MAP).forEach(([key, value]) => {
        output = output.replace(new RegExp(value, 'g'), key);
    });

    output = output.replace(/[^a-z0-9\s_-]/g, '');

    output = output.replace(/[\s_]+/g, '-');

    output = output.replace(/-+/g, '-');

    output = output.replace(/^-+|-+$/g, '');

    return output;
}

export default slug