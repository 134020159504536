import { Button, Col, Row } from "antd";
import "./styles.css";
import {
  UserFormAction,
  UserQuantity,
  UserSearch,
  UsersList,
} from "../../components/User";
import { useEffect, useState } from "react";
import { getAllUsers } from "../../app/features/users/thunks";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { getDepartments } from "../../services/department";
import TitlePage from "../../common/TitlePage";

const initStateModal = {
  isOpenModal: false,
  isCreate: false,
  titleModal: "",
  buttonOkModal: "",
  editData: {},
};

export default function User() {
  const [stateModal, setStateModal] = useState(initStateModal);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 18,
    totalCount: 0,
  });
  const [loadingData, setLoadingData] = useState(false);
  const [totalUser, setTotalUser] = useState();
  const [department, setDepartmentData] = useState([]);

  useEffect(() => {
    getUsers();
    return () => {};
  }, [dispatch, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    getDepartment();
    return () => {};
  }, []);

  const getDepartment = async () => {
    try {
      const res = await getDepartments();
      if (res) {
        setDepartmentData(res.data);
      }
    } catch (error) {}
  };

  const onLoadMore = () => {
    const newPageSize = pagination.pageSize + 18;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newPageSize,
    }));
    setUsers(
      users.concat(
        [...new Array(20)].map(() => ({
          loading: true,
        }))
      )
    );
  };

  const loadMore =
    pagination.pageIndex * pagination.pageSize < pagination.totalCount ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button className="load-more-btn" onClick={onLoadMore}>
          Xem thêm
        </Button>
      </div>
    ) : null;

  const getUsers = async (filter = {}) => {
    try {
      setLoadingData(true);
      const sort = {
        sortBy: "Id",
        sortType: "DESC",
      };
      const res = await dispatch(
        getAllUsers({ ...filter, ...pagination, ...sort })
      );
      if (res.payload.data) {
        setUsers(res.payload?.data);
        setPagination((prevPagination) => ({
          ...prevPagination,
          totalCount: res.payload?.totalCount,
        }));
        setTotalUser(res.payload?.totalCount);
        setLoadingData(false);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const actionUsers = (data, type) => {
    switch (type) {
      case "create":
        getUsers();
        break;
      case "update":
        getUsers();
        break;
      case "delete":
        getUsers();
        break;
      case "search":
        getUsers(data);
        break;
      // case "cancel":
      //   getUsers();
      //   break;
      default:
        break;
    }
  };

  const createUser = () => {
    setStateModal({
      isOpenModal: true,
      isCreate: true,
      titleModal: "Tạo mới nhân viên",
      buttonOkModal: "Thêm",
      editData: null,
    });
  };

  const updateUser = (data) => {
    setStateModal({
      isOpenModal: true,
      isCreate: false,
      titleModal: "Chỉnh sửa nhân viên",
      buttonOkModal: "Chỉnh sửa",
      editData: data,
    });
  };

  const closeModal = () => {
    setStateModal(initStateModal);
  };

  return (
    <>
      <TitlePage>Danh sách nhân viên</TitlePage>
      <Row className="!mx-0">
        <Col span={21}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <UserSearch actionUsers={actionUsers} department={department} />
            </Col>

            <Col className="flex items-center">
              <UserQuantity totalUser={totalUser} />
            </Col>
          </Row>
        </Col>
        <Col span={3}>
          <Button
            onClick={createUser}
            size="large"
            className="align-right text-[0.9rem]"
            type="primary"
          >
            <PlusOutlined />
            Tạo nhân viên
          </Button>
          <UserFormAction
            stateModal={stateModal}
            closeModal={closeModal}
            actionUsers={actionUsers}
            department={department}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <UsersList
            loadingData={loadingData}
            updateUser={updateUser}
            actionUsers={actionUsers}
            loadMore={loadMore}
            users={users}
            department={department}
          />
        </Col>
      </Row>
    </>
  );
}
