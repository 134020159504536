import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { createUser, updateUser } from "../../app/features/users/thunks";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";

function UserFormAction({ stateModal, closeModal, actionUsers, department }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isOpenModal, isCreate, titleModal, buttonOkModal, editData } =
    stateModal;
  const [departmentName, setDepartmentName] = useState([]);
  const [disableDepartment, setDisableDepartment] = useState(false);
  const userDetails = useAppSelector(
    `${ReducersName.Users}.userLoginInfo.user`
  );
  const userId = userDetails ? userDetails.id : null;
  const ownerUser = editData && editData.Id == userId;

  // useEffect(() => {
  //   if (userDetails.isLeader && userDetails.departmentId !== null)
  //     getDepartmentName(userDetails.departmentId);
  // }, []);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
        departmentId: editData?.DepartmentId,
        isActive: true,
      });
    }
    return () => {
      form.resetFields();
    };
  }, [isOpenModal, editData, form]);

  // const getDepartmentName = async (id) => {
  //   try {
  //     const res = await getDepartmentDetail(id);
  //     setDepartmentName(res.departmentName);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getDepartment = async () => {
  //   try {
  //     const res = await getDepartments();
  //     if (res) {
  //       setDepartmentData(res.data);
  //     }
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   getDepartment();
  // }, []);

  const onFinish = async (fieldValues) => {
    if (isCreate) {
      const res = await dispatch(createUser(fieldValues));
      if (res.payload.success) {
        message.success("Tạo mới nhân viên thành công.");
        actionUsers(res.payload?.data, "create");
        closeModal();
      } else {
        for (let error in res.payload.errors) {
          res.payload.errors[error].forEach((err) => {
            message.error(err);
          });
        }
      }
    } else {
      let dataUpdate = {
        ...fieldValues,
        id: editData.Id,
        isLeader:
          fieldValues?.isLeader !== undefined
            ? fieldValues.isLeader
            : editData.IsLeader,
        isSuperUser:
          fieldValues?.isSuperUser !== undefined
            ? fieldValues.isSuperUser
            : editData.IsSuperUser,
        isActive:
          fieldValues?.isActive !== undefined
            ? fieldValues.isActive
            : editData.IsActive,
        isBlock:
          fieldValues?.isBlock !== undefined
            ? fieldValues.isBlock
            : editData.IsBlock,
        avataUrl: editData.AvataUrl,
        dateOfBirth: editData.DateOfBirth,
      };

      const res = await dispatch(updateUser(dataUpdate));
      if (res.payload.success) {
        message.success("Chỉnh sửa nhân viên thành công.");
        actionUsers(res.payload?.data, "update");
        closeModal();
      } else {
        for (let error in res.payload.errors) {
          res.payload.errors[error].forEach((err) => {
            message.error(err);
          });
        }
      }
    }
  };

  const changeSuperUser = (value) => {
    if (value) {
      setDisableDepartment(true);
      form.resetFields(["departmentId"])
    } else {
      setDisableDepartment(false);
    }
  }

  return (
    <>
      <Drawer
        title={titleModal}
        open={isOpenModal}
        onClose={closeModal}
        width={720}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          initialValues={{
            isActive: true,
            username: editData?.UserName,
            fullName: editData?.FullName,
            userEmail: editData?.UserEmail,
            phoneNumber: editData?.PhoneNumber,
            departmentId: editData?.DepartmentId,
            isBlock: editData?.IsBlock,
            isLeader: editData?.IsLeader,
            isSuperUser: editData?.IsSuperUser,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="username"
                label="Tên đăng nhập"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên đăng nhập!",
                  },
                  {
                    pattern: /^[a-z0-9]+$/,
                    message:
                      "Tên đăng nhập chỉ được chứa các ký tự thường từ a đến z và các chữ số từ 0 đến 9!",
                  },
                ]}
              >
                <Input
                  placeholder="Nhập tên đăng nhập..."
                  disabled={!isCreate}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {isCreate ? (
                <Form.Item
                  name="password"
                  label="Mật khẩu"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mật khẩu!",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Nhập mật khẩu..."
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              ) : (
                <Form.Item name="password" label="Mật khẩu">
                  <Input.Password
                    type="password"
                    placeholder="Nhập mật khẩu..."
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="fullName"
                label="Tên nhân viên"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên nhân viên!",
                  },
                ]}
              >
                <Input placeholder="Nhập tên nhân viên..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="userEmail"
                label="Địa chỉ email"
                rules={[
                  {
                    type: "email",
                    message: "Địa chỉ email không hợp lệ!",
                  },
                ]}
              >
                <Input placeholder="Nhập địa chỉ email..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label="Số điện thoại"
                rules={[
                  {
                    pattern: /^0\d{9}$/,
                    message: "Số điện thoại phải bắt đầu bằng số 0!",
                  },
                ]}
              >
                <Input
                  placeholder="Nhập số điện thoại..."
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {userDetails.isSuperUser ? (
              <Col span={12}>
                <Form.Item
                  name="departmentId"
                  label="Bộ phận"
                  rules={[
                    ({ getFieldValue }) => ({
                      required: !getFieldValue("isSuperUser"),
                      message: "Vui lòng chọn một Bộ phận!",
                    }),
                  ]}
                >
                  <Select placeholder="Chọn bộ phận" disabled={disableDepartment}>
                    {department?.map((item, index) => (
                      <Select.Option key={index} value={item.Id}>
                        {item.DepartmentName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : userDetails.isLeader ? (
              <Col span={12}>
                <Form.Item
                  name="departmentId"
                  label="Bộ phận"
                  initialValue={userDetails.departmentId}
                >
                  <Select placeholder="Chọn bộ phận" disabled>
                    {department?.map((item, index) => (
                      <Select.Option key={index} value={item.Id}>
                        {item.DepartmentName}
                      </Select.Option>
                    ))}
                  </Select>
                  {/* <Select disabled>
                    <Select.Option value={userDetails.departmentId}>
                      {departmentName}
                    </Select.Option>
                  </Select> */}
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            {!ownerUser ? (
              <Col span={12}>
                <Form.Item name="isLeader" label="Chỉ định làm quản lý">
                  <Switch
                    className="switch-role"
                    disabled={
                      !userDetails.isSuperUser &&
                      userDetails.isLeader &&
                      userDetails.id === editData?.Id
                    }
                  />
                </Form.Item>
              </Col>
            ) : (
              userDetails.isSuperUser && (
                <Col span={12}>
                  <Form.Item name="isLeader" label="Chỉ định làm quản lý">
                    <Switch
                      className="switch-role"
                      disabled={
                        !userDetails.isSuperUser &&
                        userDetails.isLeader &&
                        userDetails.id === editData?.Id
                      }
                    />
                  </Form.Item>
                </Col>
              )
            )}{" "}
            {userDetails.isSuperUser && (
              <Col span={12}>
                <Form.Item name="isSuperUser" label="Chỉ định làm admin">
                  <Switch className="switch-role" onChange={changeSuperUser} />
                </Form.Item>
              </Col>
            )}
            {!isCreate && !ownerUser && (
              <Col span={12}>
                <Form.Item name="isBlock" label="Trạng thái tài khoản">
                  <Select>
                    <Select.Option value={false}>Hoạt động</Select.Option>
                    <Select.Option value={true}>Khóa</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          {/* <Row gutter={16} className="hidden">
            <Col span={24}>
              <Form.Item
                name="avataUrl"
                label="Ảnh đại diện"
                rules={[
                  {
                    required: false,
                    message: "please enter url description",
                  },
                ]}
              >
                Ảnh
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={16} justify={"end"}>
            <Col span={24}>
              <Space>
                <Button danger type="primary" onClick={closeModal} className="">
                  Hủy
                </Button>
                <Button
                  htmlType="submit"
                  className="border-[#ff9900]  hover:!border-[#ff9900] !bg-[#ff9900] !text-[#fff] hover:opacity-80 transition"
                >
                  {buttonOkModal}
                </Button>
              </Space>
            </Col>
          </Row>

          <Col span={12} hidden>
            <Form.Item name="isActive" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

export default UserFormAction;
